import './App.css';
import React from 'react';

function App() {
  return (
    <div className="App">
      <div class="theBall-outer"><div class="theBall"></div></div>
      <h1 style={{ fontWeight: 'bold' }}>HeLlO!</h1>
      <p>I'm anjir, a software engineer based in Seattle. I'm experienced primarily in frontend, platform, and
        data engineering in addition to functional experience working on data-engineering workflows for nlp pipelines.
        I'm actively working on creating technical content for this blog which will focuses on application development
        with one-off posts exploratoring llm/ml related work. Most importantly this is
        where I turn to in order to tinker, think out loud, and document things I'm developing.
      </p>

      <p>I tend to publish sporadically and every now and then embark on shiny objects, post art work
        or share cool music I discover, and <i>try my best</i> to ensure the content on my blog doesn't contain
        misinformation - if you have feedback or concerns please dont hesitate to reach out.</p>

      <p>Previously I worked at <a href="https://bustle.com">Bustle</a> doing platform engineering, and started out as a self taught engineer.

        You can find more work I've produced on <a href="https://github.com/fgtrzah">github</a>,
        <a href="https://github.com/AnjirHossain">alternate github</a>, <a href="#">
          cv</a>, or <a href="mailto=admin@fgtrz.com">get in touch</a>
      </p>

      <sub><span style={{ fontWeight: 'bold' }}>ps:</span><br />
        this domain is supposed to denote fig treez without using vowels - my name means "fig" in other languages
        so that's what I went with at the time of planning this site. Hope you enjoy!</sub>
    </div>
  );
}

export default App;
